/*
 * Component: block-area
 * --------------------
 */
.row.toolbar{
    margin-bottom: 10px;
    margin-top: -14px;
}
.block-area {
    width: 100%;
    float: left;
    height: 35px;
    background: linear-gradient(to bottom, #F0C097 0%, #D2860D 51%, #CB810C 71%, #EDB17E 97%, #F1C49D 50%);
}
.shortcut {
    width: 110px;
    height: 35px;
    display: inline-block;
    text-align: center;
    padding: 5px 5px 0;
    position: relative;
    vertical-align: top;
}
.tile {
    position: relative;
    margin-bottom: 15px;
}
.tile, .tile-dark, .tile-title, .table th {
        border-right: 2px solid #E6E5E5;
}
.block-area.shortcut-area a{color:white;}
.block-area.shortcut-area a:hover,.block-area.shortcut-area a:active,.block-area.shortcut-area a:focus{
    background-color: #C56114;
    color: #D1D4D6;
    -webkit-box-shadow: 2px 2px 5px #111;
}
.small {
    color: #fff;
    margin-left:5px;
}
.small, small {
    font-size: 14px;
}
.shortcut img{
    text-align: left !important;
}